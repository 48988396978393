.orderIfor {
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, .05), 0 0 0 1px rgba(0, 0, 0, .08);
}
.order-total-price {
    width: 100%;
}
.grand {
    border-bottom: 2px solid #4d4d4d;
    border-top: 1px solid #4d4d4d;
}
td:last-child, th:last-child {
    text-align: right;
}