.container {
  width: 50%;
  margin-left: 25%;
  justify-content: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.addButton {
  background-color: #03c9d7;
  color: white;
  border: 2px solid #03c9d7;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
}

.backButton {
  background-color: #ff5c8e;
  color: white;
  border: 2px solid #ff5c8e;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
}

.editButton {
  background-color: #8be78b;
  color: white;
  border: 2px solid #8be78b;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
}

.backIcon {
  size: 35px;
  color: white;
  background-color: #ff5c8e;
  border-radius: 2px;
  padding: 8px;
}

img {
  height: 80px;
  width: 80px;
  object-fit: contain;
  padding: 5px;
  border-radius: 15px;
  display: inline-block;
}

.block-icon {
  position: relative;
  display: inline-block;
}

.icon-tag {
  color: #ff5c8e;
  background-color: white;
  padding: 2px;
  font-weight: bolder;
  margin: 5px;
  size: 15px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 18px !important;
  height: 18px !important;
}

.icon-belowtag {
  color: #ff5c8e;
  background-color: white;
  padding: 2px;
  font-weight: bolder;
  margin: 5px;
  size: 15px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
  width: 15px !important;
  height: 15px !important;
}

.modal {
  width: 450px;
  margin: auto;
}

/*dropdown Css */
.dropdown-check-list {
  display: inline-block;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 50px 5px 10px;
  border: 1px solid #ccc;
  width: 100%;
}

.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid black;
  border-top: 2px solid black;
  padding: 5px;
  right: 10px;
  top: 20%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
}

.dropdown-check-list ul.items li {
  list-style: none;
}

.dropdown-check-list.visible .anchor {
  color: #0094ff;
}

.dropdown-check-list.visible .items {
  display: block;
}

.scroll-bar{

  overflow: auto;
  height:600px;
  /* border:1px solid #003C7E; */
}

/* width */
::-webkit-scrollbar {
width: 10px;
border:1px solid grey;
}

/* Track */
::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey; 
border-radius: 5px;
}



/* muilty tag input add distributor page  */

.tagClass {
  background-color: #2626261c;
  border-radius: 8px;
  padding: 2px 0 2px 5px;
  margin:2px;
}
.tagInputFieldClass {
  width: 100%;
  /* border: 1px solid #dee2e6 !important;
  border-radius: 5px; */
  padding: 0.275rem 0.375rem;
}
.tagInputFieldClass:focus-visible {
  outline: none;
}
.selectedClass {
  width: 100%;
  min-height: 100%;
  border: 1px solid #dee2e6 !important;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

}
.removeClass {
  margin: 0 0 0 5px;
  color: rgba(128, 128, 128, 0.708);
  font-size: 18px;
}