/* addservice.css */
.add-service-page {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .add-service-page h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .add-service-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .add-service-form input[type="text"],
  .add-service-form input[type="number"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .add-service-form button[type="submit"] {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .add-service-form button[type="submit"]:hover {
    background-color: #0056b3;
  }
  
  .add-service-form button[type="submit"]:focus {
    outline: none;
  }
  
  .add-service-form button[type="submit"]:active {
    background-color: #004180;
  }
  