@import url("https://fonts.googleapis.com/css2?family=PT+Serif&family=Montserrat:wght@200;300;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600");

/* user order page */
.bg-secondary {
  background-color: white !important;
}
.crd h4 {
  color: #66cdaa;
  font-size: 1.5rem;
  font-weight: 500;
    line-height: 1.2;
} 
.new2 {
  margin: 1rem 0;
}
.search-box form {
  text-align: center;
} 
.search-box form input[type=text] {
  border: 2px solid grey;
  border-radius: 5px 0 0 5px;
  height: 40px;
  padding: 0 25px;
  width: 50%;
}
.search-box form input[type=submit] {
  background: #003c7e;
  border: none;
  border-radius: 0 5px 5px 0;
  color: #fff;
  cursor: pointer;
  height: 40px;
  outline: none;
  position: relative;
  width: auto;
  padding: 8.5px 15px 10px 15px;
}
.btn-primary {
  background-color: #0d6efd !important;
  color: #fff;
  margin: 10px 0;
}
.btn-primary:hover {
  background-color: #5a8dee !important;
}
.scroll-bar {
  width: calc(75.7% - 20px);
  overflow: auto;
  border: none;
  border-left: 1px solid #003C7E;
  background-color: #e5e7eb6d !important;
  padding: 20px !important;
}
.filter-order, .filter {
  margin: 1rem 0;
  font-weight: 500;
  color: #475f7b;
  font-size: 1.25rem;
}
.filter {
  font-size: 1.75rem;
}
.bg-light {
  background-color: transparent !important;
} 
.bg-light .form-check-label, .text-capitalize {
  font-size: 1rem !important;
  font-weight: 500 !important;
} 


/*  user information tab */
.tab {
  cursor: pointer;
  padding-bottom: 5px;
  margin: 0 30px 0 0;
  color: #000000d5 !important;
  font-size: 1.2rem !important;
}

.tab:hover {
  color: blue;
}

.tab-active {
  border-bottom: 2px solid #00000079;
  padding: 0 0 5px 0;
  transition: border-bottom 0.3s ease, color 0.3s ease;
}


.box {
  border-radius: 4px;
  height: auto;
  background-color: #03c9d7;
  color: white;
  font-family: "PT Serif", serif;
  border: 3px solid #03c9d7;
}

.backIcon {
  size: 35px;
  color: white;
  background-color: #ff5c8e;
  border-radius: 2px;
  padding: 8px;
  margin-top: 10px;
  margin-right: 10px;
}

.tablefont {
  font-family: "PT Serif", serif;
  padding: 10px;
}

.tablefont1{
  font-family:"Poppins";
    padding: 10px;
   font-weight: bold !important;
}

td {
  padding: 15px;
  margin: 10px;
}

.fixbox {
  width: auto;
}

.footerfont {
  font-family: "PT Serif", serif;
  color: #03c9d7;
}

.flex-container {
  display: flex;
  width: 100%;
}

.right {
  float: right;
  margin-right: 10px;
  text-align: right;
}

.flex-container > div {
  margin: 10px;
  padding: 20px;
  font-size: 30px;
  width: 100%;
}

hr.new1 {
  border-top: 1px solid white;
}

/*  */

.footerfont{
  background-color: #e5e7eb;
  border-radius: 10px;
}
.DtlsName{
  font-size: 18px;
  color: cadetblue;
  margin-top: 10px;
}
.ViewPrice{
  color:#ff5c8e ;
  font-weight: bold;
  font-size: 19px;
  
}
.ViewPriceFinal{
  color:blue ;
  font-weight: bold;
  font-size: 20px;
}
.asign1{
  color: sandybrown;
  padding: -105px;
  position: absolute;
  margin-left: 45px;
  width: 42%;
}
.asign5{
  color: sandybrown;
  padding: -105px;
  position: absolute;
  margin-left: 127px;
  width: 42%;
}
.asign4{
  color: sandybrown;
  padding: -105px;
  position: absolute;
  margin-left: 131px;
  width: 42%;
}
.asign3{
  color: sandybrown;
  padding: -105px;
  position: absolute;
  margin-left: 62px;
  width: 42%;
}
.asign2{
  color: sandybrown;
  padding: -105px;
  position: absolute;
  margin-left: 18px;
  width: 42%;
}
.Assign_hedding1{
  margin-left: 40px;
    margin-top: -30px;
    padding: 0px;
}
.finailAmoutnSign{
  font-weight: bold;
  margin-left: 5px;
  font-size: 14px;
  color: red;
}
.EmplayeDetailsInput{
  width: 100%;
  border: solid;
  margin-top: 0px;
  display: -webkit-box;
  height: 48px;
  border: solid #8080808a 1.5px;
  border-radius: 9px;
  padding: 11px;
  color: gray;
  margin-left: 75px;


}
.EmplayeInput {
  width: 100%;
  border: solid #8080808a 1.5px;
  border-radius: 9px;
  padding: 11px;
  margin-top: 1px;
  display: flex;
  margin-left: 73px;
  color: grey;
}

.AssignBtn{
  margin-left: 528px;
  margin-top: -34px;
  display: flex;
 
}

#addBTN:hover{
  background-color: #03c9d7 !important;
  border: none;
}

.backButton{
  margin-left: -105px;
}

.EmplayBox{
 
  display: block; margin-top: 10px;margin-left: -156px;
}
#EmplyDitels{
  margin-top: 0px !important;
  margin-left: 0px !important;
  font-size: 27px !important;
  color: darkblue !important;
  display: flex;
}
.lablestyle,
.lablestyle1 {
  color: brown;
  font-size: 16px;
  margin-left: 77px;
}

#EmplyDitels {
  margin-top: -57px !important;
  margin-left: 0px !important;
  font-size: 27px !important;
  color: darkblue !important;
  display: flex;
}


.css-1m15cj8{
  border-radius: 35px;
}
#EmplyDitels {
  margin-top: -57px !important;
  margin-left: -79px !important;
  font-size: 27px !important;
  color: darkblue !important;
  display: flex;
}

.AssignButton {
  background-color: #03c9d7;
  padding: 9px;
  color: #fff;
  border-radius: 7px;
  border: none;
  margin-top: 12px;
  margin-left: -138p;
  margin-left: 77px;
  width: 73px;
  display: flex;
}
.backButton1 {
  background-color: #ff5c8e;
  padding: 9px;
  color: #fff;
  border-radius: 7px;
  border: none;
  margin-top: -42px;
  margin-left: -138p;
  margin-left: 156px;
  width: 73px;
  display: flex;
}

/* ASSIGNED POP-UP And SHIP POP-UP */
.assigned_name {
  font-size: 26px;
  color: darkblue;
  text-align: center;
  margin-top: -1px;
}
.assignedOrderNo {
  color: brown;
  margin-left: 5px;
  margin-top: 15px;
  font-size: 19px;
}

.input {
  /* border: 2px solid; */
  margin-right: -4px;
  background-color: beige;
  width: 169px;
  margin-left: 5px;
  height: 31px;
  border-radius: 5px;
}

.assignedShip {
  background-color: #03c9d7;
  width: 72px;
  text-align: center;
  padding: 7px;
  color: #fff;
  border-radius: 7px;
  margin-top: 48px;
  /* display: flex; */
  position: absolute;
  margin-left: 127px;
}
.assignedcancel {
  background-color: #ff5c8e;
  width: 72px;
  text-align: center;
  padding: 7px;
  color: #fff;
  border-radius: 7px;
  float: left;
  margin-left: 209px;
  margin-top: 47px;
}

.BntList {
  margin-top: -18px;
  margin-left: -62px;
}
.tbody1{
  color:navy;
  font-size: large;
}


/* Container Styles */
.info-container {
  /* font-family: Arial, sans-serif; */
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 10px;
  max-width: 90%;
  margin: 6% auto auto auto;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
}

.info-container h2 {
  color: #66cdaa;
  margin-bottom: 20px;
  text-align: start;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;

}

/* Grid Layout */
.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

/* Field Styles */
.info-field {
  display: flex;
  flex-direction: column;
}

.info-field label {
  font-weight: 400;
  color: #333;
  margin-bottom: 5px;
}

.info-value {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #333;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  word-wrap: break-word;
}

