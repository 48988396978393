.container {
  width: 50%;
  margin-left: 25%;
  justify-content: center;
}

.addButton {
  background-color: #03c9d7;
  color: white;
  border: 2px solid #03c9d7;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
}

.addBtn, .order_addBtn {
  padding: 5px 15px;
  color: rgb(0, 60, 126);
  border: 1px solid rgb(0, 60, 126);
  border-radius: 4px;
  cursor: pointer;
}
.addBtn:hover {
  background-color: rgb(0, 60, 126);
  color: white;
}

.backButton {
  background-color: #ff5c8e;
  color: white;
  border: 2px solid #ff5c8e;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
}

.editButton {
  background-color: #8be78b;
  color: white;
  border: 2px solid #8be78b;
  border-radius: 4px;
  padding: 8px;
  margin: 2px;
}

.backIcon {
  size: 35px;
  color: white;
  background-color: #ff5c8e;
  border-radius: 2px;
  padding: 8px;
}

img {
  height: 220px;
  width: 160px;
  object-fit: cover;
  padding: 5px;
  border-radius: 15px;
  display: inline-block;
}

.block-icon {
  position: relative;
  display: inline-block;
}

.icon-tag {
  color: #ff5c8e;
  background-color: white;
  padding: 2px;
  font-weight: bolder;
  margin: 5px;
  size: 15px;
  position: absolute;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 12px !important;
  height: 12px !important;
}

.modal {
  width: 450px;
  margin: auto;
}
.input-unitQuantity:focus {
   outline: 0px !important;
}

.focushide:focus, .focushide:focus-within {
  outline: none !important;
}