.page-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: rgb(0, 60, 126);
  }

  .main-Notifi-container {
    display: flex;
    justify-content: center;
  }
   
   
  .container {
    width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f2f2f2;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      justify-content: center;
      margin-left: 0 !important;
      margin-right: 0 !important;

  }

  .rmsc .dropdown-container {
    border: none !important;
    width: 16rem !important;
    min-width: 100% !important;
  }
   
    .options {
      display: flex;
      margin-bottom: 20px;
      width: 100%;
    }
   
    .option-label {
      display: flex;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
      text-transform: uppercase;
      padding: 10px;
    }
   
  .button {
      border-radius: 20px;
      padding: 10px 20px;
      background-color: rgb(0, 60, 126);
      color: white;
      border: none;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 10px;
      cursor: pointer;
      width: 400px;
      transition: background-color 0.3s;
    }
   
  .button:hover {
    background-color: white;
    color: rgb(0, 60, 126);
    border: 1px solid rgb(0, 60, 126);
    font-weight: bold;
  }
   
  .textbox,
  .textarea {
    border: 1px solid #ccc;
    border-radius: 20px;
    margin: 10px;
    padding: 10px;
    width: 100%;
    max-width: 400px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    resize: none;
    box-sizing: border-box;
  }
   
  .textarea {
    height: 150px;
    line-height: 1.5;
  }
   
  .textbox:focus,
  .textarea:focus {
    outline: none;
    border-color: white;
  }
   
   
    .option-circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid black;
      margin-right: 10px;
      background-color: #fff;
     
    }
   
    .option-circle.selected {
      background-color: #00ff00;
    }
   
    .option-label:hover .option-circle {
      box-shadow: 0 0 15px #00ff00;
    }
   
    .option-label input[type="radio"] {
      margin-right: 10px;
    }
   
    .option-label .option-text {
      margin-right: 10px;
    }